import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css'

import App from './App';
import Splash from './components/Splash'
import * as serviceWorker from './serviceWorker';

if (window.matchMedia('(display-mode: standalone)').matches) {
	ReactDOM.render(<App />, document.getElementById('root'))
}else{
	ReactDOM.render( <Splash/>, document.getElementById('root') )
}

window.toggleIsLoading = (bool=false) => {
	if( !!bool ){
		document.querySelector('body').classList.add('loading')
	}else{
		document.querySelector('body').classList.remove('loading')
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
