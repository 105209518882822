import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom'
import _ from 'lodash'

import './App.scss';
import Loading from './components/common/Loading'
import OperationsList from './components/OperationsList';
import NewOperation from './components/NewOperation';
import MaterialsList from './components/MaterialList';
import Login from './components/Login'
import EditOperation from './components/EditOperation';

const parseUserFromLocalStorage = json => {
	try{
		return JSON.parse( json )
	}catch( err ){
		console.log(`🌈 JSON parse error: ${err}`)
		return false
	}
}

const CheckUser = withRouter( props => {
	let data = parseUserFromLocalStorage( localStorage.getItem('operator') )
	if( _.isEmpty( data ) && props.location.pathname !== '/login' ){
		props.history.push('/login')
		return null
	}else{
		return props.children
	}
})

const App = () => {
	return (
		<>
			<BrowserRouter basename={'/app'}>
				<CheckUser>
				<section className="container">
					<Loading type="grow" variant="info"/>	
					<Switch>
						<Redirect exact from='/' to='/operation-list'/>
						<Route path="/login" component={Login}/>
						<Route path="/operation-list/:customerId?" component={OperationsList}/>
						<Route path="/new-operation" component={NewOperation}/>
						<Route exact path="/operation/:operationId/edit" component={EditOperation}/>
						<Route exact path="/operation/:operationId/materials" component={MaterialsList}/>
					</Switch>
				</section>
				</CheckUser>
			</BrowserRouter>
		</>
	)
}

export default App;
