import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'

import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'
import { faListAlt, faPlusSquare  } from '@fortawesome/free-solid-svg-icons'

const parseUserFromLocalStorage = json => {
	try{
		return JSON.parse( json )
	}catch( err ){
		console.log(`🌈 JSON parse error: ${err}`)
		return false
	}
}

function Header(props) {

	const [ operator, setOperator] = useState({})

	useEffect(() => {
		let data = parseUserFromLocalStorage( localStorage.getItem('operator') )
		if( !_.isEmpty( data ) ){
			setOperator( data )
		}else{
			props.history.push('/login')
		}
		// eslint-disable-next-line
	}, [])

	const goToLogin = history => {
		localStorage.removeItem('operator')
		history.push('/login')
	}

	const Button = withRouter( ({ history }) => (
		<button
			type="button"
			className="btn btn-link mb-2 py-0 text-dark"
			onClick={ ev => goToLogin(history) }
		>{operator.name}</button>
	) )
	
	return (
		<>
			<header>
				<div className="d-flex">
					<div>
						<Link to="/operation-list">
							<FaIcon icon={ faListAlt }/>
						</Link>
					</div>
					<div className="flex-fill text-center title">
						<Button/>
					</div>
					<div>
						<Link to="/new-operation">
							<FaIcon icon={ faPlusSquare }/>
						</Link>
					</div>
				</div>
			</header>
		</>
		)
	}

	export default withRouter(Header)