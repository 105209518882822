import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function Loading({variant, type, show=false, ...props}) {
	return (
		<div className={`loading-wrapper ${ show ? '':'off' }`}>
			<Spinner variant={variant} animation={type}/>
			<p>Caricamento...</p>
		</div>
		)
	}
	