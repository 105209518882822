import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import _ from 'lodash'

import Header from './common/Header';
import Operation from './Operation'

export default function OperationsList({ match }) {
	const { params } = match
	const [operations, setOperations] = useState([])
	const [operator, setOperator] = useState({})
	
	// Filters
	//const [historyId, setHistoryId] = useState( () => { if( _.isEmpty( match.params ) ){return 0;} else return match.params.customerId } )
	
	useEffect( () => {
		console.log(params, params.customerId, params.customerId || false)
		getOperations()
		setOperator( getOperatorFromLocalStorage() )
		// eslint-disable-next-line
	}, [] )

	const getFilter = item => {

		// Operation Filter
		if( !_.isEmpty( match.params ) && params.customerId > 0 )
			return item.clienti_id_verbose === ~~params.customerId

		// Operator Filter
		if( operator && operator.id > 0 )
			return item.operatori_id_verbose === operator.id

		// Without Filter
		return true
	}

	const getOperatorFromLocalStorage = () => {
		try{
			return JSON.parse( localStorage.getItem('operator') )
		}catch( err ){
			console.log( `🌈 JSON parse error: ${err}` )
		}
	}
	
	const getOperations = async () => {
		window.toggleIsLoading(true)
		// const res = await fetch( `https://offertepiscineinterrate.it/rsappfront/api.php?action=getInterventi&verbose=true&orderBy[data]=desc&where[operatori_id]=${ operator.id }` )
		const res = await fetch( `https://offertepiscineinterrate.it/rsappfront/api.php?action=getInterventi&verbose=true` )
		const data = await res.json()
		if( data.result ){
			setOperations(data.response)
		}else{
			console.log('Error during fetch 👎')
		}
		window.toggleIsLoading(false)
	}

	return (
		<>
			<Header/>
			<div className="history-badge">
				<span className={`badge badge-${ params.customerId || false ? 'info' : 'success' }`}>{ params.customerId || false ? 'Storico Interventi' : 'Lista Interventi' }</span>
			</div>
			<ul className="list-unstyled mt-2">
				{ operations
					.filter( getFilter )
					.sort((a,b) => new Moment(a.data).format('YYYYMMDDHHmm') - new Moment(b.data).format('YYYYMMDDHHmm'))
					.reverse()
					.map( operation => (
					<li key={ operation.id }>
						<Operation
							id={ operation.id }
							customer={ operation.clienti_id }
							customerId={ operation.clienti_id_verbose }
							activity={ operation.attivita_id }
							activity_id={ operation.attivita_id_verbose }
							state={ operation.stato }
							operator={ operation.operatori_id }
							date={ operation.data }
							note={ operation.note }
						/>
					</li>
				) ) }
			</ul>
		</>
	)
}
