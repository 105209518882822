import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { Collapse } from 'react-bootstrap'

import Moment from 'react-moment'
import 'moment/locale/it'

import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'
import { faHistory  } from '@fortawesome/free-solid-svg-icons'

export default function Operation({ id, customer, customerId, activity, activity_id, state, operator, date, note }) {

    const [open, setOpen] = useState(false)

    const activityColors = {
        1: 'success',  // Apertura
        2: 'danger',  // Chiusura
        3: 'warning',  // Manutenzione
        4: 'primary'   // Installazione
    }

    return (
        <div className="card mb-3 text-center">
            <div className="card-body">
                {/*<Link to={`/customer-operations-history/${id}`} className="history-link">*/}
                <Link to={`/operation-list/${customerId}`} className="history-link">
                    <FaIcon icon={ faHistory }/>
                </Link>
                <h5 className={ `${state ? 'text-muted' : ''} m-0` }>
                    { customer }
                </h5>
                <small style={{fontSize:'small'}} className="text-muted"><strong>Operatore: </strong>{ operator }</small>
                <p>
                    <Moment format="LL HH:mm">{ date }</Moment>
                    <br/>
                    <span className={`badge badge-${activityColors[activity_id]}`}>{ activity }</span>
                </p>
                <div className="d-flex">
                    { note.length ?
                        <Button
                            className={`mr-auto`}
                            variant="secondary"
                            size="sm"
                            onClick={ ev => setOpen( !open ) }
                            aria-controls={`${id}-collapse-note`}
                            aria-expanded={ open }
                        >Mostra Note</Button>
                        : ''
                    }
                    <Link
                        className="ml-auto btn btn-sm btn-primary"
                        to={ `/operation/${id}/materials` }
                    >Lista materiali</Link>
                </div>
                <Collapse in={ open }>
                    <div style={{fontSize:'small'}} id={`${id}-collapse-note`} className="text-left">
                        {note}
                    </div>
                </Collapse>
            </div>
        </div>
    )
}
