import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import Header from './common/Header';

import { Alert } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import moment from 'moment'

export default function MaterialList(props) {
	const activityColors = {
        1: 'success',  // Apertura
        2: 'danger',  // Chiusura
        3: 'warning',  // Manutenzione
        4: 'primary'   // Installazione
    }

	const [operation, setOperation] = useState({})
	const [materials, setMaterials] = useState([])
	const [usedMaterials, setUsedMaterials] = useState([])
	const [ showError, setShowError ] = useState(false)
	const [ error, setError ] = useState('')

	// Form data
	const [quantity, setQuantity] = useState(0)
	const [materialId, setMaterialId] = useState(0)

	useEffect(() => {
	  getOperation()
	  getMaterials()
	  getUsedMaterials()
	  // eslint-disable-next-line
	}, [])

	const handleQuantity = (ev, n) => {
		ev.preventDefault()
		if( quantity + n >= 0 ){
			setQuantity( quantity + n )
		}else{
			setQuantity(0)
		}
	}

	const getOperation = async operationId => {
		const res = await fetch( `https://offertepiscineinterrate.it/rsappfront/api.php?action=getInterventi&verbose=true&where[id]=${props.match.params.operationId}` )
		const data = await res.json()
		if( data.result ){
			setOperation( _.head( data.response ) )
		}else{
			console.log(`⛔ Error during fetch: ${data.response}`)
		}
	}

	const getMaterials = async () => {
		const res = await fetch( 'https://offertepiscineinterrate.it/rsappfront/api.php?action=getMateriali&verbose=true' )
		const data = await res.json()
		if( data.result ){
			setMaterials( data.response.map( i => ({ id:i.id, name:i.nome, original:i })) )
		}else{
			console.log(`⛔ Error during fetch: ${data.response}`)
		}
	}

	const getUsedMaterials = async operationId => {
		const res = await fetch( `https://offertepiscineinterrate.it/rsappfront/api.php?action=getMateriali_impiegati&verbose=true&where[interventi_id]=${props.match.params.operationId}` )
		const data = await res.json()
		if( data.result ){
			//setUsedMaterials( sortAndSumUsedMaterials(data.response) )
			setUsedMaterials( data.response )
		}else{
			console.log(`⛔ Error during fetch: ${data.response}`)
		}
	}
	
	/*const sortAndSumUsedMaterials = arr => {
		let result = []
		arr.reduce( (res, current) => {
			if( !res[ current.materiali_id_verbose ] ){
				res[ current.materiali_id_verbose ] = {...current, quantita:0}
				result.push( res[ current.materiali_id_verbose ] )
			}

			res[ current.materiali_id_verbose ].quantita += ~~current.quantita
			return res

		}, {} )
		return result
	}*/

	const saveUsedMaterials = async ev => {
		if( ev ) ev.preventDefault()
		let isEdit = false
		window.toggleIsLoading(true)
		
		if( quantity <= 0 || materialId === 0 ){
			window.toggleIsLoading(false)
			setError( '⚡ Errore di validazione: assicurati di aver inseritto tutti i campi in modo corretto.' )
			setShowError( true )
			console.log('⚡ Validation error')
			return
		}
		
		let recordToEdit = usedMaterials
		.filter( item => item.materiali_id_verbose === materialId )
		.reduce( (finalItem, currentItem) => {
			currentItem.quantita  += ~~finalItem.quantita || 0
			finalItem = currentItem
			return finalItem
		}, {} )
		if( !_.isEmpty( recordToEdit ) ) isEdit = true
		
		let serializedData = `interventi_id=${props.match.params.operationId}&materiali_id=${materialId}&quantita=${quantity}`
		let urlToFetch = 'https://offertepiscineinterrate.it/rsappfront/api.php?action=insertmateriali_impiegati'
		
		if( isEdit ){
			serializedData = `id=${recordToEdit.id}&interventi_id=${props.match.params.operationId}&materiali_id=${materialId}&quantita=${~~recordToEdit.quantita + quantity}`
			urlToFetch = 'https://offertepiscineinterrate.it/rsappfront/api.php?action=editMateriali_impiegati'
		}
		
		const res = await fetch( urlToFetch, {
			method: 'POST',
			body: serializedData,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		} )
		
		const data = await res.json()
		if( data.result ){
			setQuantity( 0 )
			setMaterialId( 0 )
			getUsedMaterials()
			setShowError( false )
			console.log( '🔝 Saved.' )
		}else{
			setError( `🤔 ${data.response}` )
			setShowError( true )
			console.log( `⛔ Error during fetch: ${data.response}`)
		}
		window.toggleIsLoading(false)
	}
	
	const deleteusedMaterials = async (ev, id) => {
		if( ev ) ev.preventDefault()
		window.toggleIsLoading(true)
		const res = await fetch('https://offertepiscineinterrate.it/rsappfront/api.php?action=deleteMateriali_impiegati', {
			method: 'POST',
			body: `id=${id}`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
		const data = await res.json()
		if( data.result ){
			// delete item from usedMaterials array or fetch the data
			getUsedMaterials()
		}else{
			console.log(`⛔ Error during fetch: ${data.response}`)
		}
		window.toggleIsLoading(false)
	}

	return (
		<>
			<Header/>
			<Alert variant="danger" className="my-1" style={ showError ? {display:'block'} : {display:'none'} }>
				<small>{ error }</small>
			</Alert>
			<div className="text-center my-2">
				<div className="card my-2" style={{boxShadow:'0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'}}>
					<div className="card-body p-3 text-left">
						<h6 className="m-0">{ operation.clienti_id }</h6>
						<small>{ moment( operation.data ).format('LL HH:mm') }</small>
						<br/>
						<span className={`badge badge-${activityColors[ operation.attivita_id_verbose ]}`}>{ operation.attivita_id }</span>

					</div>
					<div className="card-footer p-3">
						<div className="d-flex">
							<Link
								className="ml-auto btn btn-sm btn-primary"
								to={`/operation/${operation.id}/edit`}
							>
								Aggiungi note
							</Link>
						</div>

					</div>
				</div>
			</div>
			<hr/>
			<form>
				<Typeahead
					id="materials"
					className="my-1"
					labelKey="name"
					placeholder="-- Seleziona il Materiale --"
					onChange={ selected => {if( _.has(selected[0], 'id') ){setMaterialId( selected[0].id )}} }
					options={ materials }
					filterBy={['name']}
					emptyLabel="Nessun risultato"
					maxResults={20}
					selected={ materials.filter( item => item.id === materialId ) }
					paginationText="Mostra altri risultati"
				/>
				<p className="my-2">Quantità <strong>{ quantity }</strong></p>
				<div className="counter d-flex text-center my-2">
					<div className="flex-fill mx-1">
						<button className="btn btn-primary btn-block" onClick={ ev => handleQuantity(ev, -5)}>
							-5
						</button>
					</div>
					<div className="flex-fill mx-1">
						<button className="btn btn-primary btn-block" onClick={ ev => handleQuantity(ev, -1)}>
							-1
						</button>
					</div>
					<div className="flex-fill mx-1">
						<button className="btn btn-primary btn-block" onClick={ ev => handleQuantity(ev, +1)}>
							+1
						</button>
					</div>
					<div className="flex-fill mx-1">
						<button className="btn btn-primary btn-block" onClick={ ev => handleQuantity(ev, +5)}>
							+5
						</button>
					</div>
				</div>
				<button
					type="button"
					className="btn btn-success btn-block"
					onClick={ ev => saveUsedMaterials( ev ) }
					>Salva</button>
			</form>
			<hr/>
			<table className="table table-sm">
				<thead>
					<tr>
						<th style={{fontSize:'small'}}>Descrizione</th>
						<th style={{fontSize:'small'}}>Q.ta</th>
						<th style={{fontSize:'small'}}> - </th>
					</tr>
				</thead>
				<tbody>
					{ usedMaterials.map( ({id, materiali_id, quantita}) => (
						<tr key={id}>
							<td><small>{ materiali_id }</small></td>
							<td><span className="badge badge-success">{ ~~quantita }</span></td>
							<td><button type="button" onClick={ ev => deleteusedMaterials(ev, id) } className="btn btn-link badge badge-danger">Elimina</button></td>
						</tr>
					) ) }
				</tbody>
			</table>
		</>
		)
	}
	