import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'

import { Alert } from 'react-bootstrap'
import Header from './common/Header';

const EditOperation = props => {

	const { match: { params } } = props

	const [ done, setDone ] = useState(false)
	const [ showError, setShowError ] = useState(false)
	const [ error, setError ] = useState('')

	// Form Data
	const [ operation, setOperation ] = useState({})
	const [ note, setNote ] = useState('')

	useEffect(() => {
		getOperation( params.operationId )
		// eslint-disable-next-line
	}, [])

	const getOperation = async operationId => {
		const res = await fetch( `https://offertepiscineinterrate.it/rsappfront/api.php?action=getInterventi&verbose=true&where[id]=${operationId}` )
		const data = await res.json()
		if( data.result ){
			setNote( _.head( data.response ).note )
			setOperation( _.head( data.response ) )
			console.log( _.head( data.response ) )
		}else{
			console.log(`⛔ Error during fetch: ${data.response}`)
		}
	}
	
	const updateOperation = async ev => {
		window.toggleIsLoading(true)
		
		let serializedData = `id=${operation.id}&note=${ encodeURI( note ) }`
		
		const res = await fetch( `https://offertepiscineinterrate.it/rsappfront/api.php?action=editInterventi`, {
			method:'POST',
            body: serializedData,
            headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
            }
		} )
		const data = await res.json()
		if( data.result ){
			console.log('😎😁 Data saved. ')
			setDone(true)
		}else{
			setError( `🤔 ${data.response}` )
			setShowError( true )
			console.log( `🤔 error: ${data.response}` )
		}
		window.toggleIsLoading(false)

	}

	return (
		<>
			{ done ? <Redirect to={`/operation/${operation.id}/materials`} /> : '' }

			<Header/>
			<Alert variant="danger" className="my-1" style={ showError ? {display:'block'} : {display:'none'} }>
				<small>{ error }</small>
			</Alert>
			
			<h5><Link to={`/operation/${operation.id}/materials`}>{ operation.clienti_id }</Link></h5>
			<label>Note Intervento:</label>
			<textarea
				name="note"
				rows="5"
				value={note}
				className={`form-control my-1`}
				onChange={ ev => setNote( ev.target.value ) }
			>Scrivi le note intervento...</textarea>
			<button
				className="btn btn-block btn-success"
				onClick={ updateOperation }
			>Salva</button>

		</>
		)
	}

export default EditOperation