import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import {Typeahead} from 'react-bootstrap-typeahead'
import moment from 'moment'
import _ from 'lodash'

import { Alert } from 'react-bootstrap'
import Header from './common/Header';

export default function NewOperation() {

	const [ customers, setCustomers ] = useState([])
	const [ activities, setActivities ] = useState([])
	const [ showError, setShowError ] = useState(false)
	const [ error, setError ] = useState('')
	const [ operationId, setOperationId ] = useState(0)

	// Form data
	const [ customer, setCustomer ] = useState(0)
	const [ activity, setActivity ] = useState(0)
	const [ date, setDate ] = useState('')
	const [ note, setNote ] = useState('')

	// Success Form
	const [done, setDone] = useState(false)

	useEffect( () => {
		getCustomers()
		getActivities()
	}, [])

	const getCustomers = async () => {
		// return axios.get( 'https://offertepiscineinterrate.it/rsappfront/api.php?action=getMateriali&verbose=true' )
		const res = await fetch('https://offertepiscineinterrate.it/rsappfront/api.php?action=getClienti&verbose=true')
		const data = await res.json()
		setCustomers( data.response.map( i => ({ id:i.id, name:i.denominazione, original:i }) ) )
	}
	
	const getActivities = async () => {
		const res = await fetch('https://offertepiscineinterrate.it/rsappfront/api.php?action=getAttivita&verbose=true')
		const data = await res.json()
		setActivities( data.response.map( i => ({ id:i.id, name:i.nome, original:i }) ) )
	}

	const getOperatorFromLocalStorage = () => {
		try{
			return JSON.parse( localStorage.getItem('operator') )
		}catch( err ){
			console.log( `🌈 JSON parse error: ${err}` )
		}
	}

	const handleSubmit = async ev => {
		ev.preventDefault()
		window.toggleIsLoading(true)
		
		const operator = getOperatorFromLocalStorage()
		
		if(
			!(customer > 0) ||
			!(activity > 0) ||
			!(operator.id > 0) ||
			!(date.length > 0)
		){
			setError( `✋ Errore di validazione, compila tutti i campi` )
			setShowError( true )
			console.log(`✋ Validation Error, check all fields`)
			return
		}
		
		let serializedData = `clienti_id=${customer}&attivita_id=${activity}&operatori_id=${operator.id}&data=${ moment(date).format('YYYY-MM-DD HH:mm:ss') }&note=${note}`
		
		const res = await fetch( `https://offertepiscineinterrate.it/rsappfront/api.php?action=insertinterventi`, {
			method:'POST',
			body: serializedData,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		} )
		const data = await res.json()
		if( data.result ){
			console.log( 'Submit! 😎' )
			console.log('😁 Data saved. ')
			setOperationId( data.response )
			setDone(true)
		}else{
			setError( `🤔 ${data.response}` )
			setShowError( true )
			console.log( `🤔 error: ${data.response}` )
		}
		window.toggleIsLoading(false)
	}

	return (
		<>
			{ done ? <Redirect to={`/operation/${ operationId }/materials`} /> : '' }

			<Header/>
			<Alert variant="danger" className="my-1" style={ showError ? {display:'block'} : {display:'none'} }>
				<small>{ error }</small>
			</Alert>

			<h5 className="text-center">Nuovo Intervento</h5>
			<form onSubmit={ handleSubmit }>
				<label className="d-block m-0 p-0">Cliente</label>
				<Typeahead
					id="customers"
					className="my-1"
					labelKey="name"
					placeholder="-- Cliente --"
					onChange={ selected => {if( _.has(selected[0], 'id') ){setCustomer( selected[0].id )}} }
					options={ customers }
					filterBy={['name']}
					emptyLabel="Nessun risultato"
					maxResults={20}
					paginationText="Mostra altri risultati"
				/>
				<label className="d-block m-0 p-0">Tipo di attività</label>
				<Typeahead
					id="activities"
					className="my-1"
					labelKey="name"
					placeholder="-- Attività --"
					onChange={ selected => {if( _.has(selected[0], 'id') ){setActivity( selected[0].id )}} }
					options={ activities }
					filterBy={['name']}
					emptyLabel="Nessun risultato"
					maxResults={20}
					paginationText="Mostra altri risultati"
				/>
				<label className="d-block m-0 p-0">Data intervento</label>
				<input
					placeholder="Data"
					type="datetime-local"
					className="form-control my-1"
					value={ date }
					onChange={ ev => setDate( ev.target.value.substr(0, 16) ) }
					/>
				<label className="d-block m-0 p-0">Note aggiuntive</label>
				<textarea
					name="note"
					className="form-control my-1"
					value={ note }
					onChange={ ev => setNote( ev.target.value ) }
				>Note</textarea>
				<button
					type="submit"
					className="btn btn-success btn-block"
				>Salva</button>
			</form>
		</>
	)
}
	