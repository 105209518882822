import React, { useEffect, useState } from 'react'
import _ from 'lodash'
//import { isProperty } from '@babel/types';

const parseUserFromLocalStorage = json => {
	try{
		return JSON.parse( json )
	}catch( err ){
		console.log(`🌈 JSON parse error: ${err}`)
		return false
	}
}

export default function Login(props) {

	const [ operators, setOperators ] = useState([])
	
	useEffect(() => {
		getOperators()
		let data = parseUserFromLocalStorage( localStorage.getItem('operator') )
		if( !_.isEmpty( data ) ){
			props.history.push('/operation-list')
		}else{
			console.log('no operator')
		}
		// eslint-disable-next-line
	}, [])

	const getOperators = async () => {
		const res = await fetch('https://offertepiscineinterrate.it/rsappfront/api.php?action=getOperatori&verbose=true')
		const data = await res.json()
		setOperators( data.response.map( i => ({ id:i.id, name:i.nome, original:i }) ) )
	}

	const saveUserToLocalStorage = user => {
		localStorage.removeItem( 'operator' )
		localStorage.setItem('operator', JSON.stringify( user ))
		props.history.push('/operation-list')
		//window.location.reload()
	}

	return (
		<>
			<div className={`switch-user-overlay show`}>
				<ul>
					<li>Seleziona un Operatore</li>
					{ operators.map( item => (
						<li key={item.id}>
							<button
								onClick={ ev => saveUserToLocalStorage( item ) }
								className="btn btn-primary btn-block my-2"
							>
								{item.name}
							</button>
						</li>
					)) }
				</ul>
			</div>
		</>
		)
	}
	