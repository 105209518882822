import React from 'react'

import './../Splash.scss'

import logo from './../img/rslogo.png'
import addIcon from './../img/square-add.svg'
import shareIcon from './../img/share.svg'

export default function Splash() {
	return (
		<>
		<div className="container">
			<div className="row wrapper">
				<div className="col-md-12">
					<img src={logo} alt="RS Piscine Logo"/>
					<h2>RS App</h2>
					<p>
						<small>
							Istruzioni per l'utilizzo dell'app di RS Piscine sul tuo smartphone.
							<br/>
							<strong>premessa:</strong> assicurati di aver aperto il link con Safari
						</small>
					</p>
					<ol>
						<li>
							<strong>1. </strong>Clicca l'icona di Condivisione <br/> ( simile a questa <img src={ shareIcon } alt="share icon" /> )
						</li>
						<li><strong>2. </strong>Scorri leggermente verso destra e clicca l'icona "Salva nella Home" <br/> ( cerca un icona simile a questa <img src={ addIcon } alt="add icon" /> )
						</li>
					</ol>
				</div>
			</div>
		</div>
		</>
		)
	}
	